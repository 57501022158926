.chartSvg {
  cursor: pointer;

  svg {
    width: 30px;
    height: 30px;
    padding: 5px;

    &:hover {
      background-color: #dfe7ea;
      border-radius: 3px;

      path {
        fill: #3995f5;
        opacity: 1;
      }

      .svg_underline {
        fill: #3995f5;
        opacity: 1;
      }
    }

    path {
      fill: #b3b3b3;
      opacity: 1;
    }
  }
}

.chartSvgComment {
  &:hover {
    background-color: #dfe7ea;
    border-radius: 3px;

    svg {
      color: #3995f5;

      path {
        fill: #dfe7ea;
      }

      circle {
        fill: #3995f5;
        opacity: 1;
      }
    }
  }

  svg {
    color: #b3b3b3;

    circle {
      fill: #b3b3b3;
    }

    path {
      fill: #FFFFFF;
    }
  }
}

.ag-theme-alpine {
  font-family: Poppins, Helvetica, 'sans-serif' !important;
  font-size: 14px;

  .ag-cell {
    line-height: 50px!important;
  }

  .ag-header {
    border-bottom: 1px solid #EBEDF3 !important;
    background-color: #FFF !important;
  }

  .ag-header-row {
    font-weight: 400 !important;
  }

  .ag-row-selected {
    background-color: rgba(33, 150, 243, 0.3)!important;
  }

  .ag-row-odd, .ag-row-even {
    background-color: #FFF!important;
  }

  .ag-header-icon {
    color: #B5B5C3
  }

  .ag-header-cell {
    padding: 0!important;
  }

  .ag-row {
    color: #3F4254;
    height: 50px !important;
  }

  .ag-ltr .ag-cell {
    text-align: center;
    border: none !important;
  }

  .ag-root-wrapper {
    border: none !important;
    border-radius: 0 !important;
  }

  .ag-header-cell, .ag-theme-alpine .ag-header-group-cell {
    border: none;
    background-color: #FFF;
    color: #B5B5C3
  }
}

.asd {
  color: blue;
}