.switch {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1rem;

  label {
    margin: 0;
  }

  input:empty {
    margin-left: -999px;
    height: 0;
    width: 0;
    overflow: hidden;
    position: absolute;
    opacity: 0;

    & ~ span {
      display: inline-block;
      position: relative;
      float: left;
      width: 1px;
      text-indent: 0;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &:before,
      &:after {
        position: absolute;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        top: 0;
        bottom: 0;
        left: 0;
        content: ' ';
        -webkit-transition: all 100ms ease-in;
        transition: all 100ms ease-in;
      }
    }
  }

  input[disabled] {
    cursor: not-allowed;

    & ~ span {
      &:after,
      &:before {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }

  &.switch-icon input:checked ~ span:after {
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    line-height: 1;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    content: "KG";
  }
}

.switch.switch-outline.switch-dark input:empty ~ span:before {
  border: 2px solid #EBEDF3;
  background-color: transparent; }

.switch.switch-outline.switch-dark input:empty ~ span:after {
  background-color: #EBEDF3; }

.switch.switch-outline.switch-dark input:checked ~ span:before {
  border: 2px solid #181C32;
  background-color: transparent; }

.switch.switch-outline.switch-dark input:checked ~ span:after {
  color: #ffffff;
  background-color: #181C32; }

.switch input:empty ~ span {
  margin: 2px 0;
  height: 30px;
  width: 57px;
  border-radius: 15px;
}

.switch input:empty ~ span:before,
.switch input:empty ~ span:after {
  width: 54px;
  border-radius: 15px; }

.switch input:empty ~ span:after {
  height: 24px;
  width: 24px;
  top: 3px;
  bottom: 3px;
  margin-left: 3px;
  font-size: 0.65em;
  text-align: center;
  vertical-align: middle; }

.switch input:checked ~ span:after {
  margin-left: 26px; }

