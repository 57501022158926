.checks {line-height: 20px}

input[type="checkbox"] {  /* 실제 체크박스는 화면에서 숨김 */
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip:rect(0,0,0,0);
    border: 0
}

input[type="checkbox"] + label {
    display: inline-block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

input[type="checkbox"] + label:before {  /* 가짜 체크박스 */
    content: ' ';
    display: inline-block;
    width: 21px;  /* 체크박스의 너비를 지정 */
    height: 21px;  /* 체크박스의 높이를 지정 */
    line-height: 21px; /* 세로정렬을 위해 높이값과 일치 */
    margin: -2px 8px 0 0;
    text-align: center;
    vertical-align: middle;
    background: #fafafa;
    border: 1px solid #cacece;
    border-radius : 3px;
    box-shadow: 0px 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
}

input[type="checkbox"] + label:active:before,
input[type="checkbox"]:checked + label:active:before {
    box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
}

input[type="checkbox"]:checked + label:before {  /* 체크박스를 체크했을때 */
    content: '\2714';  /* 체크표시 유니코드 사용 */
    color: #99a1a7;
    text-shadow: 1px 1px #fff;
    background: #e9ecee;
    border-color: #adb8c0;
    box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0 -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
}

input[type="checkbox"] + label {
    padding-left: 30px;
}

input[type="checkbox"] + label:before {
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 0;
    opacity: .6;
    box-shadow: none;
    border-color: #63c7d2;
    -webkit-transition: all .12s, border-color .08s;
    transition: all .12s, border-color .08s;
}

input[type="checkbox"]:checked + label:before {
    position: absolute;
    content: "";
    width: 10px;
    top: -5px;
    left: 5px;
    border-radius: 0;
    opacity:1;
    background: transparent;
    border-color:transparent #63c7d2 #63c7d2 transparent;
    border-top-color:transparent;
    border-left-color:transparent;
    -ms-transform:rotate(45deg);
    -webkit-transform:rotate(45deg);
    transform:rotate(45deg);
}
