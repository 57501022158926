/* 전체 폰트 */
/*body {margin: 0; font-family: 'Noto Sans', Arial, sans-serif!important; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;}*/
body {
    margin: 0;
    font-family: Poppins, Noto Sans KR, Helvetica, 'sans-serif' !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* background img height 100% */
html, body {
    height: 100%;
}

/* 적용시 기본 폰트 무시하고 폰트 적용 */
pre, p {
    font-family: Poppins, Noto Sans KR, Helvetica, 'sans-serif' !important;
    font-size: 1rem;
}

code, button, h1, h2, h3, h4, h5, h6 {
    font-family: Poppins, Noto Sans KR, Helvetica, 'sans-serif' !important;
}

/* Chrome Input 자동 완성 시 Background-Color 안 바뀌게 설정 */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-transition: background-color 9999s ease-out;
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

div::-webkit-scrollbar, textarea::-webkit-scrollbar  {
    width: 5px;
    height: 5px;
}

div::-webkit-scrollbar-track, textarea::-webkit-scrollbar-track {
    background-color: #f0f0f0;
}

div::-webkit-scrollbar-thumb, textarea::-webkit-scrollbar-thumb {
    background: linear-gradient(45deg, #a5d4de, #63c7d2);
    border-radius: 10px
}

div::-webkit-scrollbar-button, textarea::-webkit-scrollbar-button {
    display: none;
}

button:focus, button:active, button:visited {
    /*border: none!important;*/
}

button {
    outline: none !important;
}

/* 파일 필드 숨기기 */
input[type="file"] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

/* ul 적용시 앞 표시 삭제 및 간격 삭제 */
ul {
    list-style: none;
    margin: 0;
    padding: 0
}

/* a 태그 사용시 글씨 검은색 고정 밑줄 삭제 */
a:link {
    color: black;
    text-decoration: none !important;
}

a:visited {
    color: black;
    text-decoration: none !important;
}

a:hover {
    color: black;
    text-decoration: none !important;
}

/*Table 태그 기본 속성 삭제*/
table tr th {
    font-weight: normal !important;
}

body.swal2-height-auto {
    height: 100% !important;
}

/* ReadOnly 배경 회색 */
.ReadOnly {
    background-color: #eaeaea;
}

/* ProgressBar 색 설정 */
.pg-low {
    background-color: rgb(125, 157, 232);
}

.pg-normal {
    background-color: rgb(140, 216, 152)
}

.pg-high {
    background-color: rgb(212, 220, 138)
}

.pg-veryHigh {
    background-color: rgb(255, 115, 115);
}

.text-black {
    color: #000 !important;
}


/* 숨김처리 */
.Hide {
    display: none !important;
}


/* 보임처리 */
.Show {
    display: block !important;
}


/* Margin 0 고정*/
.no-margin {
    margin: 0 !important;
}


/* Padding 0 고정*/
.no-padding {
    padding: 0 !important;
}


/* 오른쪽 정렬 */
.txtR {
    text-align: right !important;
    justify-content: right !important;
}


/* 왼쪽 정렬 */
.txtL {
    text-align: left !important;
    justify-content: left !important;
}


/* 가운데 정렬 */
.txtC {
    text-align: center !important;
    justify-content: center !important;
}


/*div 한 줄 정렬*/
.dp-flex {
    display: flex !important;
}


/* pointer 표시 */
.pointer {
    cursor: pointer
}

.w-20 {
    width: 20% !important
}

.w-35 {
    width: 35% !important
}

.fs-80 {
    font-size: 80px!important;
}

.lh-30 {
    line-height: 30px;
}

.wp-450 {
    width: 450px!important;
}

.hp-415 {
    height: 415px!important;
}

.fs-14 {
    font-size: 14px;
}

.chartDot {
    width: 6px;
    height: 6px;
    display: inline-block;
    border-radius: 100%;
    margin-bottom: 2px;
    margin-right: 0.25rem;
}

.chartDot_in {
    background-color: #1BC5BD;
}

.chartDot_out {
    background-color: #f64e60;
}

.chkIn {
    color: #1BC5BD !important;
    font-size: 13px;
}

.chkOut {
    color: #F64E60 !important;
    font-size: 13px;
}


/*Api Setting */
.popup-content {
    width: 480px !important;
    max-height: 500px;
    border-radius: 25px;
    padding: 0 !important;
    border: none !important;
}

.non-backdrop {
    opacity: 0!important;
}

.modal-send-form {
    position: absolute!important;
    right: 0;
    bottom: 0;
    height: auto;
    min-height: 0!important;
    margin: 1.75rem!important;
    box-shadow: 0 0 30px -20px rgba(0, 0, 0, 0.45);
}

.ag-header-select-all {
    padding: 0 17px;
}

.ag-header-cell-label {
    justify-content: center;
}

.react-datepicker-wrapper {
    display: block
}

.ag-theme-alpine .ag-row-selected {
    background-color: rgba(33, 150, 243, 0.3)!important;
}

.ag-theme-alpine .ag-ltr .ag-cell {
    text-align: center;
}

.selected-row {
    background-color: #bbe2ea !important
}

.form-control:disabled {
    background-color: #FFF !important;
}

div.mdb-datatable div.mdb-datatable-info, div.mdb-datatable div.mdb-dataTables_paginate, div.mdb-datatable div.mdb-datatable-entries {
    padding: 0 !important;
}

.table.table-borderless.mdb-dataTable tr {
    height: 42px !important;
    line-height: 42px !important;
}

.table.table-borderless.mdb-dataTable tr td {
    padding: 0 !important;
}

.mdb-dataTable-head tr, .mdb-dataTable-head tr th {
    padding: 0 !important;
    height: 48px;
    line-height: 48px
}

.ag-header-cell-label {
    justify-content: center;
}

.ag-cell-focus {
    border: none !important;
    outline: none
}

.ag-theme-alpine .ag-header-icon {
    color: #B5B5C3!important;
}

.mobile.col-12 {
    padding: 0!important;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
}

.MuiTooltip-popper {
    z-index: 1000000!important;
}

.modalSomato .modal-dialog .modal-content {
    border-radius: .5rem;
    overflow: hidden;
}

.modalGoal .modal-dialog {
    max-width: 650px!important;
}

.c-black {
    color: #333333!important;
}
